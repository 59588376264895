import React from 'react'
import { useHistory,Link } from 'react-router-dom';
import Header from '../header/Header';
import AuthMobileDrawer, { HeaderElement } from '../../molecules/mobile-drawer/MobileDrawer';
import HomeIcon from '@material-ui/icons/Home';
import { Typography, Grid, Button, Box } from '@material-ui/core';
import logo from '../../../img/DSicon.png';
import useStyles from './styles';

const NoAuthHeader: React.FC = () => {

  const history = useHistory();
  const classes = useStyles();

  const headerElements: Array<HeaderElement> = [
    { name: 'Login', icon: <HomeIcon />, action: () => {
      history.push("/login")
     } },
    //  {
    //   name: 'Sign Up', icon: <HomeIcon />, action: () => {
    //     history.push("/sign_up")
    //    }
    //  }
  ]

  const moveToMarketingWebsite = (page:string) => {
    window.location.href = `https://www.dealseal.app/${page}.html`
  }

  return (
    <Header
      leftSectionContent={
        <Box className={classes.buttonContainer}>
        {/* <Button variant="outlined" onClick={ headerElements[0].action }  style={{
          color:"#d8648c",
          marginRight:"10px", 
          borderRadius:"10px", 
          border: "2px solid #d8648c",
          padding: "5px 40px",
          fontWeight: 600,
          fontSize: "11px"
          }}>{headerElements[0].name}</Button> */}
        <Button onClick={()=> moveToMarketingWebsite('download-page') } variant="contained" color="secondary">
          Sign Up Free!
        </Button>
      </Box>
      }
      mobileDrawerContent={
        <AuthMobileDrawer headerElements={headerElements}/>
      }>
      <Grid container item alignItems='center'>
        <Grid container md={10} direction="row" className={classes.toolbarTitle}>
              <img src={logo} alt="Logo" className={classes.logoApp} onClick={()=>moveToMarketingWebsite('index')} />
              <label className="company-name">Deal Seal</label>
        </Grid>
        <Grid container md={2} direction="row-reverse">
        </Grid>
      </Grid>
    </Header>
  )
}

export default NoAuthHeader;