import gql from "graphql-tag";

export const GetAllPromocodes = gql`
query getAllPromotionCodes {
  getAllPromotionCodes{
   id,
   code,
   type,
   redeemed_at,
   status  
 }
}`

export const UpdatePromotionCodeRedemption = gql`
mutation($promotioncode: String){
    updatePromotionCode(promotioncode:{
      code:$promotioncode
    }){
        status
        endTrialPeriod
    }
  }
`;