export enum ACCOUNT_ROLE {
  ROOT = 'ROOT',
  ADMIN = 'ADMIN'
}

export enum ACCESS_LEVEL {
  META = 'META',
  VIEW_BASIC = 'VIEW_BASIC',
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export enum RESPONSE_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export enum PERMISSION_TYPE {
  DATA_ACCESS = 'DATA_ACCESS'
}

export enum PAYMENT_STATUS {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  TRIAL = 'TRIAL',
  INACTIVE = 'INACTIVE'
}

export enum PAYMENT_INTERVAL {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL'
}