import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    cardContent: {
      '& > *': {
        marginTop: 16,
      },
    },
    formTitle: {
      paddingTop: 10,
      marginLeft: 80,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      marginTop: 16,
      marginLeft: 80,
      // '& > *': {
      //   width: '100%',
      //   marginTop: 16,
      // },
      justifyContent: 'left',
      alignContent: 'space-between',
    },
    danger: {
      color: theme.palette.error.main,
    },
    button: {
      width: 82,
      marginTop: 15,
    },
    notAvailable: {
      display: 'flex',
      height: '94vh',
      marginTop: -17,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      verticalAlign: 'center',
      padding: '0 15%',
    },
  }),
);
export default useStyles;
