import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { UserExists } from '../../../shared/graphql/queries/user';
import { RespondToAccessRequest } from '../../../shared/graphql/queries/groups';
import { UNAUTHORIZED } from '../../../utils/error-codes';
import { showSnackbarMessage } from '../../../graphql/cache-structure';

const RespondAccessRequest: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const redirectUri = encodeURIComponent(location.pathname + location.search);
  const [rejected, setRejected] = useState(false);
  const { data: dataExists, loading: loadingExists } = useQuery(UserExists, {
    fetchPolicy: 'network-only',
    variables: {
      email: searchParams.get('email') || '',
    },
  });
  const [respondAccessRequest] = useMutation(RespondToAccessRequest);

  useEffect(() => {
    if (!loadingExists && dataExists) {
      if (!dataExists.userExists.exists) {
        showSnackbarMessage("You don't have yet an account, please create one!");
        history.push(`/sign_up?redirect_url=${redirectUri}`);
      }
    }
  }, [loadingExists, dataExists, history, redirectUri]);

  const onSubmit = (accepted: boolean) => (_: React.MouseEvent) => {
    respondAccessRequest({
      variables: {
        email: searchParams.get('email'),
        token: searchParams.get('token'),
        response: accepted,
      },
    }).then(() => {
      if (accepted) {
        history.push('/login');
      } else {
        setRejected(true);
      }
      showSnackbarMessage(`You have successfully ${accepted ? 'accepted' : 'rejected'} the data request`);
    }).catch(err => {
      let message = 'Some error happened';
      console.log(err.graphQLErrors[0]);
      if (err.graphQLErrors[0] && err.graphQLErrors[0].message.statusCode === UNAUTHORIZED) {
        // Probably login is not right
        message = 'You are not authorized for doing this action';
      }
      showSnackbarMessage(message);
    });
  };

  if (!loadingExists && dataExists) {
    return (
      <>
        <Grid container>
          <Grid item xs={1} md={3} lg={4} />
          <Grid item xs={10} md={6} lg={4}>
            {
              rejected
                ? (
                  <Card>
                    <CardContent className="template-card-content">
                      <Typography variant="body1">
                        You rejected the invitation.
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <Card>
                    <CardContent className="template-card-content">
                      <Typography variant="body1">
                        Someone has requested access to your data
                      </Typography>
                    </CardContent>
                    <CardActions className="template-card-actions">
                      <Button color="primary" variant="contained" size="large" onClick={onSubmit(true)}>
                        Accept
                      </Button>
                      <Button color="primary" size="large" onClick={onSubmit(false)}>
                        Reject
                      </Button>
                    </CardActions>
                  </Card>
                )
            }
          </Grid>
        </Grid>
      </>
    );
  }
  return null;
};

export default RespondAccessRequest;
