import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    
  },
  topContainer:{
    padding:'25px'
  },
  bottomContainer:{
    backgroundColor:'RGB(135, 143, 159,.2)',
    padding:'25px',
  },
  title:{
      color:'#000',
      fontWeight:'bolder'
  },
  titleBlue:{
      color:'#6464df',
      fontWeight:'bolder'
  },
  titlePriceBlue:{
    color:'#6464df',
    fontWeight:'bolder',
    textAlign:'end'
  },
  termsText:{
      color:'#afb0be',
      fontWeight:600
  },
  termsTextLink:{
    color:'#afb0be',
    fontWeight:600,
    textDecoration:'underline'
  },
  textLabels:{
    fontWeight:'bolder',
    textAlign:'start',
    color:'#6464df'
  },
  textNumbers:{
    fontWeight:'bolder',
    textAlign:'end',
    color:'#6464df'
  }

}))