import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLOR } from '../../../styles/colors';

const useStyles = makeStyles((theme: Theme) => createStyles({
  boldText:{
    fontWeight:'bolder'
  },
  mainTitle:{
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 36
  },
  avatarWrapper:{
    [theme.breakpoints.up('sm')]: {
      maxWidth:150
    }
  },
  userNamesWrapper:{
    [theme.breakpoints.down('sm')]: {
      paddingLeft:20,
    },
    paddingTop:10,
    paddingBottom:10,
    paddingLeft:15
  },
  endPeriodDateLabel:{
    marginTop:-10
  },
  nameLabel:{
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 20,
  },
  emailLabel:{
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    marginTop: 5
  },
  statusLabel:{
    [theme.breakpoints.down('sm')]: {
      textAlign:'center',
      justifyContent:"center"
    }
  },
  gridContainer: {
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
      '& > *': {
        padding: theme.spacing(2, 0)
      }
    },
    [theme.breakpoints.up('md')]: {
      '& > *': {
        padding: theme.spacing(0, 4)
      }
    },
  },
  input: {
    display: 'none',
  },
  rootElement: {
    display: 'flex',
    alignItems: 'center',
  },
  labelContainer: {
    flex: 1,
  },
  spanFilename: {
    flex: '0 0 30%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 16,
    paddingRight: 16,
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  cardContent: {
    '& > *': {
      marginTop: 16,
    },
  },
  btnGoSubcription:{
    width:'auto',
    padding:'10px 32.5px',
    marginTop:'-25px',
    borderRadius:'24px',
    backgroundColor: COLOR.LINK_ACTION,
    color:COLOR.WHITE,
    fontSize: 11,
    fontWeight:'bolder',
    '&:hover': {
      backgroundColor:COLOR.LINK_ACTION
    },
    [theme.breakpoints.up('md')]: {
      maxWidth:'210px',
      marginLeft:'0px',
      marginTop:'7px'
    }
  },
  cancelSubcriptionLink:{
    [theme.breakpoints.down('sm')]: {
      marginTop:-30
    },
    color: COLOR.LINK_ACTION
  },
  activeLabel:{color:COLOR.SUCCESS, textTransform:'capitalize'},
  noActiveLabel:{color:COLOR.CANCEL, textTransform:'capitalize'}
}));
export default useStyles;
