import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core//TableBody';
import TableCell from '@material-ui/core//TableCell';
import TableContainer from '@material-ui/core//TableContainer';
import TableHead from '@material-ui/core//TableHead';
import TablePagination from '@material-ui/core//TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { IContact } from '../../../shared-global/interfaces/models/contact.interface';

interface Column {
  id:
    | 'firstName'
    | 'lastName'
    | 'address'
    | 'city'
    | 'state'
    | 'primary'
    | 'zip'
    | 'phone'
    | 'email'
    | 'contactTypeId';
  label: string;
  minWidth?: number;
  align?: 'right';
}

const columns: readonly Column[] = [
  { id: 'firstName', label: 'Firstname', minWidth: 170 },
  { id: 'lastName', label: 'Lastname', minWidth: 100 },
  {
    id: 'address',
    label: 'Address',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'state',
    label: 'state',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'primary',
    label: 'primary',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'zip',
    label: 'Zip',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'phone',
    label: 'Phone',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'contactTypeId',
    label: 'Contact type',
    minWidth: 170,
    align: 'right',
  },
];

interface Data {
  id: number;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  primary: boolean;
  zip: number;
  phone: number;
  email: string;
  contactTypeId: number;
}

function createData(
  id: number,
  firstName: string,
  lastName: string,
  address: string,
  city: string,
  state: string,
  primary: boolean,
  zip: number,
  phone: number,
  email: string,
  contactTypeId: number,
): Data {
  return {
    id,
    firstName,
    lastName,
    address,
    city,
    state,
    primary,
    zip,
    phone,
    email,
    contactTypeId,
  };
}

interface IContactsTable {
  contactsList: IContact[];
}

const ContactsTable: React.FC<IContactsTable> = ({
  contactsList,
}: IContactsTable) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [contacts, setContacts] = useState<Data[] | null>();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (contactsList) {
      const formattedContacts = contactsList.map((contact: any) =>
        // @ts-ignore
        createData(
          contact.id,
          contact.firstName,
          contact.lastName,
          contact.address,
          contact.city,
          contact.state,
          contact.primary,
          contact.zip,
          contact.phone,
          contact.email,
          contact.contactTypeId,
        ),
      );
      setContacts(formattedContacts);
    }
  }, [contactsList]);

  return (
    <>
      <Typography
        variant={'h4'}
        color={'primary'}
        style={{ marginBottom: 10, marginLeft: 5 }}
      >
        Contacts
      </Typography>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!contacts &&
                contacts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={contactsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default ContactsTable;
