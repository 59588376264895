import React from 'react'
import { Avatar } from '@material-ui/core';
import useStyles from './styles';

export interface IAvatarImage {
  profileUrl: string
  size?: number
}

const AvatarImage: React.FC<IAvatarImage> = (props: IAvatarImage) => {
  const { profileUrl, size } = props;
  const classes = useStyles(size);

  return (
    <Avatar src={profileUrl} className={classes.avatar} />
  );
};

export default AvatarImage;