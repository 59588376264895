
import React from 'react';
import { useQuery } from '@apollo/client';
import useStyles from './styles';
import { MyOpportunities } from '../../../shared/graphql/queries/opportunity';
import { CSVLink } from "react-csv";
import { Grid, Typography } from '@material-ui/core';
import CloudDownLoad from '@material-ui/icons/CloudDownload';

const headers = [
    { label: "Deal Nickname", key: "dealNickname" },
    { label: "Opportunity Address", key: "opportunityAddress" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Zip", key: "zip" },
    { label: "Opportunity Value", key: "opportunityValue" },
    { label: "Commission Type", key: "commissionType" },
    { label: "Commission Percent", key: "commissionPercent" },
    { label: "Commission Company Split", key: "commissionCompanySplit" },
    { label: "Commission Flat Fee", key: "commissionFlatFee" },
    { label: "Buy Or Sell", key: "buyOrSell" },
    { label: "Close Date (Estimated or Actual)", key: "closeDate" },
    { label: "Status", key: "status" },
    { label: "Lead Source", key: "leadSource" },
    { label: "Notes", key: "notes" }
  ];

const DownloadDeals: React.FC = () => {
    const classes = useStyles();
    const { data: dataDeals, loading: dataDealsLoading, error: errordataDeals } = useQuery(MyOpportunities);
    const [deals, setDeals] = React.useState([]);
    const [isDataLoad, setIsDataLoad] = React.useState(false)

    React.useEffect(()=>{
        if(dataDeals&& dataDeals.myOpportunities && !isDataLoad){
            // console.log('Data > ',dataDeals.myOpportunities)
            const formatedData = generateDataDeals(dataDeals.myOpportunities)
            setDeals(formatedData as [])
            setIsDataLoad(true)
        }
    })

    const getDateString = (d:string) => new Date(Number(d)).toLocaleDateString();

    const generateDataDeals = (deals:any[]) => {
        let dealList: any[] = [];
        deals.forEach(deal=>{
            dealList.push(
                {
                    dealNickname: deal.opportunityName,
                    opportunityAddress: deal.opportunityAddress,
                    city: deal.opportunityCity,
                    state: deal.opportunityState,
                    zip: deal.opportunityZip,
                    opportunityValue: deal.opportunityValue,
                    commissionType: deal.commissionType,
                    commissionPercent: deal.commissionPercent,
                    commissionCompanySplit: deal.commissionCompanySplit,
                    commissionFlatFee: deal.flatFee,
                    buyOrSell: deal.type,
                    closeDate: getDateString(deal.closeDate),
                    status: deal.status.name,
                    leadSource: deal.leadSource.name,
                    notes: deal.notes
                }
            )
        })
        return dealList;
    }

    return(
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{padding:'100px 0 0 0'}}
        >
            <Typography variant='h5' align='center'>You can review all your deals on a spreedsheet</Typography>
            <CloudDownLoad style={{ fontSize: 50 }} color="primary"></CloudDownLoad>
                <CSVLink 
                    filename={`myDeals ${new Date().getMonth()}-${new Date().getFullYear()}.csv`}
                    headers={headers}
                    data={deals} 
                    style={{
                        // backgroundColor:'#6699cd',
                        padding:'5px 10px',
                        borderRadius:'5px',
                        color:'#000',
                        textDecoration:'none',
                        fontSize:'16px',
                        marginTop:'-15px'

                    }}
                    >
                    Download Deals
                </CSVLink>
            </Grid>
            )
};

export default DownloadDeals;