import {
  ApolloClient,
  from,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import { RetryLink } from '@apollo/link-retry';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import jwtDecode from 'jwt-decode';
import cache from './cache-structure';

let client: ApolloClient<NormalizedCacheObject>;

const url = process.env.REACT_APP_API_HOST ?? '';
const retryLink = new RetryLink();

const handleNoConnectionLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      // console.log(`[GraphQL error]: ${message}`);
    });
  }
  if (networkError) {
    // console.log('Network Error');
    // showToaster('Cannot refresh data because there is no internet connection');
  }
});

const getAccessToken = (): string => {
  try {
    const jwtToken = localStorage.getItem('jwt');
    if (jwtToken) {
      return jwtToken;
    }
    return '';
  } catch (e) {
    return '';
  }
};

const getRefreshToken = (): string | null => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (refreshToken) {
    return refreshToken;
  }
  return null;
};

const isTokenExpired = (): boolean => {
  const jwtToken = getAccessToken();
  if (jwtToken) {
    const decodedToken = jwtDecode<{ exp: number }>(jwtToken);
    return new Date(decodedToken.exp * 1000) < new Date();
  }
  return true;
};

const authLink = setContext((_, { headers }) => {
  const jwtToken = localStorage.getItem('jwt');
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      Authorization: `Bearer ${jwtToken as string}`,
    },
  };
});

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: 'jwt',
  fetchAccessToken: () => {
    const refreshToken = getRefreshToken();
    return fetch(`${process.env.REACT_APP_API_HOST ?? ''}/api/refresh_token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });
  },
  isTokenValidOrUndefined: () => !isTokenExpired() || typeof getAccessToken() !== 'string' || getAccessToken().length === 0,
  handleFetch: (accessToken) => {
    localStorage.setItem('jwt', accessToken);
  },
  handleError: () => {
    localStorage.clear();
    // Return to dashboard
    window.location.href = window.location.origin;
  },
});

const link = from([
  handleNoConnectionLink,
  tokenRefreshLink,
  authLink,
  retryLink,
  createUploadLink({
    uri: `${url}/graphql`,
  }),
]);

const createClient = () => new ApolloClient<NormalizedCacheObject>({
  link,
  cache,
  connectToDevTools: true,
});

export const getClient = (): ApolloClient<NormalizedCacheObject> => {
  if (!client) {
    client = createClient();
  }
  return client;
};

export const cleanCache = async (): Promise<void> => {
  await client.resetStore();
};
