import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { showSnackbarMessage } from '../../../graphql/cache-structure';
import useStyles from './styles';
import { ResetPassword as ResetPasswordQuery } from '../../../shared/graphql/queries/user';

const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!token) {
      showSnackbarMessage('Malformed request');
      history.push('/');
    }
  }, [history, token]);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('This field is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('The field is required'),
  });
  const [resetPassword] = useMutation(ResetPasswordQuery);

  const {
    handleSubmit, errors, handleChange, values,
  } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (submitValues) => {
      resetPassword({
        variables: {
          token,
          password: submitValues.password,
        },
      }).then(result => {
        if (result.data.resetPassword) {
          showSnackbarMessage('Your password was reset successfully');
          history.push('/login');
        } else {
          showSnackbarMessage('It was not possible to reset your password');
        }
      }).catch(err => console.log(err));
    },
  });

  if (token) {
    return (
      <Grid container>
        <Grid item xs={1} md={3} lg={4} />
        <Grid item xs={10} md={6} lg={4}>
          <Card>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Typography variant="h5" align="center">
                  Forgot your password?
                </Typography>
                <Typography variant="body1">
                  Introduce your new password
                </Typography>
                <div className={classes.form}>
                  <FormControl>
                    <TextField
                      id="password"
                      label="Password"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.password}
                      error={!!errors.password}
                      helperText={errors.password}
                      type="password"
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      id="confirmPassword"
                      label="Confirm Password"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.confirmPassword}
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword}
                      type="password"
                    />
                  </FormControl>
                </div>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button color="primary" variant="contained" size="large" type="submit">
                  Reset Password
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default ResetPassword;
