import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    height:"80px",
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 0)
    }
  },
  toolbarTitle: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  subNavBar: {
    '& > *': {
      padding: theme.spacing(0, 2)
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));
export default useStyles;
