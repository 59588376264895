import gql from 'graphql-tag';

export const GetAccountGroups = gql`
query getAccountGroups {
  getAccountGroups{
    users{
      id
      firstName
      lastName
      email
      currentAccount
    }
    groups{
      id
      name
      description
      accountId
    }
    userToGroups{
      id
      userId
      groupId
    }
    permissionRequests {
      id
      email
      accountId
      level
      dateRequested
      status
      groupId
      payingForUsers
    }
  }
}
`;

export const DeleteGroups = gql`
  mutation($groupIds: [Int]) {
    deleteGroups(groupIds: $groupIds) {
      deleted_ids
      errors {
        recordId
        friendlyMessage
        errorCode
      }
    }
  }
`;

export const AddGroup = gql`
  mutation($accountId: Int, $name: String, $description: String) {
    createGroup(group: { accountId: $accountId, name: $name, description: $description }) {
      id
      accountId
      name
      description
    }
  }
`;

export const AddUserToGroup = gql`
  mutation($emails: [String], $level: String, $groupId: Int) {
    requestData(requestDataInput: {emails: $emails, level: $level, groupId: $groupId}) {
      successEmails
      failEmails
      errors {
        recordId
        friendlyMessage
      }
      permissionRequests {
        id
        email
        accountId
        level
        dateRequested
        status
        groupId
        payingForUsers
      }
    }
  }
`;

export const RespondToAccessRequest = gql`
  mutation($email: String!, $token: String!, $response: Boolean!) {
    respondDataRequest(email: $email, token: $token, response: $response) {
      success
      error {
        recordId
        friendlyMessage
        errorCode
      }
    }
  }
`;

export const ResendInvitation = gql`
  mutation($email: String!, $groupId: Int) {
    resendInvitation(email: $email, groupId: $groupId ) {
      message
      success
    }
  }
`;

export const DeletePermissionRequest = gql`
  mutation($id: Int) {
    deletePermissionRequest(id: $id) {
      success
      error {
        recordId
        friendlyMessage
        errorCode
      }
    }
  }
`;

export const RemoveUserFromGroup = gql`
  mutation($ids: [Int]) {
    removeUsersFromGroups(userToGroupIds: $ids) {
      deleted_ids
      errors {
        recordId
        friendlyMessage
        errorCode
      }
    }
  }
`;

export const GetAllGroups = gql`
  query {
    getGroups {
      id
      accountId
      name
      description
    }
  }
`;

export const UploadGroupPhoto = gql`
  mutation($file: Upload!, $group_id: Int!) {
    uploadGroupImage(file: $file, groupId: $group_id) {
      id
      name
      accountId
      description
      imageUrl
    }
  }
`;
