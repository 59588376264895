import React from 'react';
import { createStyles, Grid, makeStyles, styled, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from "@material-ui/core";
import { IChargesResponse } from '../../../shared-global/interfaces/graphql/charges-response-interface';
import { IStripePaymentMethod } from '../../../shared-global/interfaces/models/stripe-payment-method';

interface PaymentChargesTableProps {
  chargesResponse: IChargesResponse
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  tableTitle:{
    fontSize:28,
    fontWeight:'bolder',
    marginBottom:10
  },
  tableHeader:{
    [theme.breakpoints.down('sm')]: {
      fontSize:12,
      fontWeight:'bold',
      borderBottom:'none',
      paddingRight:12
    },
    [theme.breakpoints.up('md')]: {
      fontSize:20,
      fontWeight:'bold',
      borderBottom:'none'
    }
  },
  cellBody:{
    [theme.breakpoints.down('sm')]: {
      fontSize:10,
      paddingTop:8,
      paddingBottom:5
    },
    [theme.breakpoints.up('md')]: {
      padding:'35px 0'
    }
  }
}))

const formatPaymentMethod = (paymentMethod?: IStripePaymentMethod) => {
  if (!paymentMethod) {
    return '';
  }
  switch (paymentMethod.type) {
    case 'card':
      return `Card ${paymentMethod.card_brand} ended in *${paymentMethod.last_four}`
    default:
      return '';
  }
}

const paymentStatusLabel = (status:string | undefined) => {
  return status==="succeeded" ? "Approved" : status
}
const paymentAmountLabel = (amount:number) => {
  return `${new Intl.NumberFormat('en-US', { style: "currency", currency: "USD" }).format(amount / 100)}`
}

const PaymentChargesTable: React.FC<PaymentChargesTableProps> = (props: PaymentChargesTableProps) => {
  const styles = useStyles()
  const { chargesResponse: { charges, paymentMethods } } = props;

  return paymentMethods.length > 0 ? (
    <Grid item container direction="column">
      <Typography variant="h6" className={styles.tableTitle}>
        Payment history
      </Typography>
      <Table>
        <TableHead>
          <TableRow >
            <TableCell  padding='none' align='left' className={styles.tableHeader}>Method</TableCell>
            <TableCell  padding='none' align='center' className={styles.tableHeader}>Amount</TableCell>
            <TableCell  padding='none' align='right' className={styles.tableHeader}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            charges.map(charge => {
              const paymentMethod = paymentMethods.find(paymentMethod => Number(charge.stripe_payment_method_id) === Number(paymentMethod.id))
              return (
                <TableRow>
                  <TableCell className={styles.cellBody} padding='none' align='left'>{formatPaymentMethod(paymentMethod)}</TableCell>
                  <TableCell className={styles.cellBody} padding='none' align='center'>{paymentAmountLabel(charge.amount)}</TableCell>
                  <TableCell className={styles.cellBody} padding='none' align='right'>{paymentStatusLabel(charge.status)}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </Grid>
  ) : null;
}

export default PaymentChargesTable;