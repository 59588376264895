import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Grid, Card, TextField, CardContent, Typography, CardActions, Button, FormControl } from '@material-ui/core';
import useStyles from './styles';
import { Login as LoginQuery } from '../../../shared/graphql/queries/user';
import { UNAUTHORIZED } from '../../../utils/error-codes';
import { isLoggedIn, showSnackbarMessage, origin as cachedOrigin } from '../../../graphql/cache-structure';

const Login: React.FC = () => {
  const classes = useStyles();
  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email().required('This field is required'),
    password: Yup.string().required('This field is required')
  });
  const [ logIn ] = useMutation(LoginQuery);
  const history = useHistory();
  const location = useLocation();
  const search_params = new URLSearchParams(location.search)
  const redirect_url = search_params.get('redirect_url')
  const origin = search_params.get('origin');

  useEffect(() => {
    if (origin) {
      cachedOrigin(origin);
    }
  }, [origin])

  const {
    handleSubmit, errors, handleChange, values
  } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      logIn({ variables: values }).then(result =>
        {
          console.log('result.data ',result.data)
          localStorage.setItem("jwt", result.data.login.token);
          localStorage.setItem("refresh_token", result.data.login.refreshToken);
          isLoggedIn(true);
          history.push(redirect_url || "/mydeals");
        }
      ).catch(err => {
        let message = "Email/password combination not found";
        if (err.graphQLErrors[0] && err.graphQLErrors[0].message.statusCode === UNAUTHORIZED) {
          // Probably login is not right
          message = err.graphQLErrors[0].message.message;
        }
        showSnackbarMessage(message);
      })
    }
  });

  return (
    <Grid container>
      <Grid item xs={1} md={3} lg={4}/>
      <Grid item xs={10} md={6} lg={4}>
        <Card>
          <form onSubmit={handleSubmit}>
            <CardContent className={classes.cardContent}>
              <Typography variant='h5' align='center'>
                Login
              </Typography>
              <div className={classes.form}>
                <FormControl>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.email}
                    error={!!errors.email}
                    helperText={errors.email}
                    type="email" />
                </FormControl>
                <FormControl>
                  <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.password}
                    error={!!errors.password}
                    helperText={errors.password}
                    type="password" />
                </FormControl>
              </div>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Link to="/forgot_my_password">
                    Forgot your password?
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button color='primary' variant="contained" size="large" type="submit">
                Login
              </Button>
            </CardActions>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
