import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor:'#6699cd'
  },
  title: {
    color:'#FFF',
    fontWeight:'bolder'
  },
  form: {
    textAlign:'center',
    height:'40px',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: 16,
    },
  },
  promoTitle:{
    color:'#FFF',textAlign:'center',marginTop:'20px'
  },
  promoForm:{
    width:'100%',
    textAlign:'center',
  },
  controlForm:{
    backgroundColor:'#FFF',
    marginBottom:'10px',
    borderRadius:'5px',
    width:'100%',

    [theme.breakpoints.up('md')]: {
      maxWidth:'300px'
    }
  },
  formWrapper:{
    marginTop:'50px',
    paddingBottom:'100px'
  },
  btnCode:{
    height:'40px',
    width:'100%',
    marginTop:'2px',
    backgroundColor:'#e7558a',
    '&:hover': {
      backgroundColor:'#CF336C'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth:'300px'
    }
  },
  btnCodePremium:{
    height:'40px',
    width:'100%',
    marginTop:'20px',
    borderRadius:'10px',
    backgroundColor:'#e7558a',
    '&:hover': {
      backgroundColor:'#CF336C'
    }
  },
  labelInput:{
    color:'#FFF',
    fontSize:'18px',
    fontWeight:'bolder',
    marginTop:'10px',
    marginBottom:'5px'
  },
  labelSecureCheckout:{
    textAlign:'center',
    fontSize:'18px',
    fontWeight:'bolder',
    color:'#FFF',
    marginTop:'10px'
  },
  cvcWrapper:{
    width:'80%',
    alignItems:'center',
    paddingTop:'17px',
    [theme.breakpoints.down('md')]: {
      paddingLeft:'10px'
    }
  },
  codeError:{width:'200px',color:'#FFF',fontSize:'16px'}

}));
export default useStyles;
