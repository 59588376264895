import gql from "graphql-tag";

export const Login = gql`
  mutation ($email: String!, $password: String!) {
    login(loginInput: { email: $email, password: $password }) {
      token
      refreshToken
      userId
      endTrialPeriod
      freepass
      accountStatus
      firstLogin
      revenuecatAppUserId
    }
  }
`;

export const MeQuery = gql`
  query me {
    me {
      id
      email
      firstName
      lastName
      settings {
        data {
          name
          value
        }
      }
      profileImage
      logInCount
      promoCode
      endTrialPeriod
      insightsUnlocked
      firstLogInAt
      zapierEnabled
      zapierKey
      account {
        id
        displayName
        active
        paymentCycleDate
        paymentStatus
        freepass
        tracker
        company
        createdDate
        modifiedDate
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation ($email: String!, $password: String!, $firstName: String!, $lastName: String!, $goal: Float, $timezone: String!, $promotionCode: String, $zipCode: String!) {
    createUser(
      user: { email: $email, password: $password, firstName: $firstName, lastName: $lastName, goal: $goal, timezone: $timezone, promotionCode: $promotionCode, zipCode: $zipCode }
    ) {
      id
      email
      firstName
      lastName
    }
  }
`;

export const CreateUser = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $goal: Float!
    $timezone: String
    $token: String
    $zipCode: String
    $promotionCode: String
  ) {
    createUser(
      user: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        goal: $goal
        timezone: $timezone
        token: $token
        zipCode: $zipCode
        promotionCode: $promotionCode
      }
    ) {
      id
      firstName
      lastName
      email
      currentAccount
    }
  }
`;

export const UpdateUserProfile = gql`
  mutation ($data: UserInputUpdate) {
    updateUser(data: $data) {
      firstName
      lastName
    }
  }
`;

export const UserExists = gql`
  query ($email: String!) {
    userExists(email: $email) {
      exists
    }
  }
`;

export const ListAllUsers = gql`
  query {
    listAllUsers {
      id
      firstName
      lastName
    }
  }
`;

export const ListAdditionalInfo = gql`
  query ($user_id: Int) {
    listAdditionalInfoByUser(user_id: $user_id) {
      status {
        id
        name
      }
      leadSources {
        id
        name
      }
    }
  }
`;

export const RequestResetPassword = gql`
  mutation ($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const ResetPassword = gql`
  mutation ($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;

export const setPassword = gql`
  mutation ($password: String!) {
    setPassword(password: $password)
  }
`;

export const SINGLE_UPLOAD = gql`
  mutation ($file: Upload!) {
    singleUpload(file: $file) {
      filename
    }
  }
`;

export const GetSettings = gql`
  query {
    settings {
      name
      value
    }
  }
`;

export const AddSettings = gql`
  mutation ($settings: [UserInputCreateSetting]!) {
    addSettings(settings: $settings) {
      success
      error {
        friendlyMessage
      }
    }
  }
`;

export const RemoteLog = gql`
  mutation ($message: String!, $level: String) {
    remoteLog(message: $message, level: $level) {
      success
    }
  }
`;

export const GetAllPromocodes = gql`
  query getAllPromotionCodes {
    getAllPromotionCodes {
      id
      code
      type
      redeemed_at
      status
    }
  }
`;

export const UpdateZapierStatus = gql`
  mutation ($zapierEnabled: Boolean) {
    updateZapierUser(zapierEnabled: $zapierEnabled) {
      status
      enable
      zapierKey
      error {
        friendlyMessage
      }
    }
  }
`;
