import gql from 'graphql-tag';

export const SINGLE_UPLOAD = gql`
mutation($file: Upload!) {
  singleUpload(file: $file) {
    filename
  }
}
`;

export const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(loginInput: { email: $email, password: $password }) {
      token
      refreshToken
      userId
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const SIGN_UP = gql`
  mutation(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    createUser(
      user: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      id
      email
      firstName
      lastName
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation(
    $id: Int
    $data: UserInputUpdate
  ) {
    updateUser(
      id: $userId
      data: {
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      firstName
      lastName
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation($refreshToken: String!) {
    refreshToken(refreshTokenInput: { refreshToken: $refreshToken }) {
      token
      userId
    }
  }
`;

export const GET_JWT_TOKEN = gql`
  query GetJwt {
    jwt @client
  }
`;

export const GET_REFRESH_TOKEN = gql`
  query GetRefreshToken {
    refreshToken @client
  }
`;
