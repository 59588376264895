import React, { useState } from 'react';
import {
  Grid,
  CardContent,
  Typography,
  Card,
  FormControl,
  TextField,
  Button,
  CardActions,
  FormHelperText,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { CreateUser } from '../../../shared/graphql/queries/user';
import useStyles from './styles';
import { showSnackbarMessage } from '../../../graphql/cache-structure';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

const SignUp: React.FC = () => {
  const classes = useStyles();
  const [token, setToken] = useState<string | null>(null);
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [cardError, setCardError] = useState<any | null>(null);
  const [signUpMutation] = useMutation(CreateUser);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get('redirect_url');
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    email: Yup.string().email().required('This field is required'),
    password: Yup.string().required('This field is required'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match').required('This field is required'),
    goal: Yup.number().required('This field is required'),
    promotionCode: Yup.string()
  });

  const onCardChange = (element: StripeCardElementChangeEvent) => {
    if (element.error) {
      setToken(null);
      setCardError(element.error);
    } else {
      setZipCode(element.value.postalCode);
      setCardError(null);
      const possibleElement = elements?.getElement(CardElement);
      if (possibleElement) {
        stripe?.createToken(possibleElement).then(token => {
          setToken(token?.token?.id || null);
        });
      }
    }
  }

  const {
    handleSubmit, errors, handleChange, values,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      promotionCode: '',
      goal: 0
    },
    validationSchema,
    onSubmit: (valuesToSubmit) => {
      if (token) {
        console.log("values to submit ", valuesToSubmit);
        signUpMutation({
          variables: {
            ...valuesToSubmit,
            token,
            timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
            zipCode
          }
        }).then(result => {
          showSnackbarMessage('Registration successful');
          history.push(redirectUrl || '/login');
        }).catch(err => {
          showSnackbarMessage(err?.message || 'An error ocurred when signing up');
        });
      } else {
        showSnackbarMessage('Some error happened while collecting the credit card info');
      }
    },
  });


  return (
    <Grid container>
      <Grid item xs={1} md={3} lg={4} />
      <Grid item xs={10} md={6} lg={4}>
        <Card>
          <form onSubmit={handleSubmit}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" align="center">
                Sign up
              </Typography>
              <div className={classes.form}>
                <FormControl>
                  <TextField
                    id="firstName"
                    label="First Name"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.firstName}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.lastName}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.password}
                    error={!!errors.password}
                    helperText={errors.password}
                    type="password"
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="passwordConfirm"
                    label="Confirm password"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.passwordConfirm}
                    error={!!errors.passwordConfirm}
                    helperText={errors.passwordConfirm}
                    type="password"
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="goal"
                    label="Goal"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.goal}
                    error={!!errors.goal}
                    helperText={errors.goal}
                    type="number"
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="promotionCode"
                    label="Promotion code"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.promotionCode}
                    error={!!errors.promotionCode}
                    helperText={errors.promotionCode}
                  />
                </FormControl>
                <FormControl
                  error={!!cardError}>
                  <CardElement
                    onChange={onCardChange}/>
                  <FormHelperText>
                    { cardError ? cardError.message : null }
                  </FormHelperText>
                </FormControl>
              </div>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button color="primary" variant="contained" size="large" type="submit" disabled={!stripe}>
                Sign up
              </Button>
            </CardActions>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SignUp;
