import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import useStyles from './styles';
import { GetCharges } from '../../../shared/graphql/queries/account';
import { AddPaymentMethodForm } from '../../organisms/add-payment-method/AddPaymentMethodForm';
import { MeQuery } from '../../../shared/graphql/queries/user';
import { ISafeUser } from '../../../shared-global/interfaces/other/safe-user.interface';
import { Grid } from '@material-ui/core';



export const SubscriptionPage: React.FC = () => {
  const classes = useStyles();
  const { data: dataMe,refetch:refetchDataMe } = useQuery(MeQuery);
  const { data: dataCharges } = useQuery(GetCharges);

  if (dataMe && dataMe.me && dataCharges) {
    const me = dataMe.me as ISafeUser;
    return ( 
              <Grid container className={classes.root} >
                <Grid item md={1} xs={false} />
                <Grid item md={10} xs={12} container direction="column" className={classes.gridContainer} spacing={2}>
                    <AddPaymentMethodForm me={me} refetchMe={refetchDataMe} /> 
                </Grid>
                <Grid item md={1} xs={false} />
            </Grid>) 
  }
  return null;
};
