import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Grid, Typography, Paper, Icon } from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import useStyles from './styles';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetContacts,
  UploadContactsQuery,
} from '../../../shared/graphql/queries/contact';
import { showSnackbarMessage } from '../../../graphql/cache-structure';
import { MeQuery } from '../../../shared/graphql/queries/user';
import ContactsTable from '../../molecules/Contacts-table/ContactsTable';
import { IContact } from '../../../shared-global/interfaces/models/contact.interface';

const UploadContacts: React.FC = () => {
  const isDesktopScreen = window.screen.width < 768;
  const classes = useStyles();
  const inputFileRef = useRef<HTMLButtonElement>(null);

  const [csvFile, setCsvFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [userId, setUserId] = useState<number>();
  const [ready, setReady] = useState(false);
  const [contactsList, setContactsList] = useState<IContact[]>();
  const [upDateData, seUpdateData] = useState<boolean>(false);

  const { data: dataMe, loading: loadingMe } = useQuery(MeQuery);
  const { data: dataContacts, loading: loadingContacts } =
    useQuery(GetContacts);
  const [uploadOpportunitiesMutation] = useMutation(UploadContactsQuery, {
    refetchQueries: [GetContacts, 'GetMycontacts'],
    errorPolicy: 'ignore',
    awaitRefetchQueries: true,
    onCompleted: () => seUpdateData(true),
  });

  const onFileChange = ({
    target: {
      value,
      validity,
      files: [file],
    },
  }: any) => {
    setErrorMessage('');
    const filename = file.name as string;
    const extension = filename.substring(filename.lastIndexOf('.') + 1);
    if (validity.valid && extension === 'csv') {
      setCsvFile(file);
    } else {
      setErrorMessage('The file you uploaded is not a CSV.');
    }
  };

  useEffect(() => {
    if (!loadingMe && dataMe) {
      if (dataMe?.me?.id) {
        setUserId(dataMe.me.id);
        setReady(true);
      }
    }
  }, [loadingMe, dataMe]);

  useEffect(() => {
    if (dataContacts && dataContacts['getMycontacts']) {
      setContactsList(dataContacts['getMycontacts']);
    }
  }, [loadingContacts, upDateData]);

  useEffect(() => {
    const element = inputFileRef.current;
    if (!element) return;

    element.addEventListener('click', () => {
      // @ts-ignore
      inputFileRef.current.value = null;
    });
  }, []);

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    if (ready && csvFile) {
      uploadOpportunitiesMutation({
        variables: {
          file: csvFile,
          userId,
        },
      })
        .then((result) => {
          if (result.data.uploadContacts.success) {
            // @ts-ignore
            inputFileRef.current.value = '';
            showSnackbarMessage(
              `${result.data.uploadContacts.successInfo.message}. Duplicate emails:[${result.data.uploadContacts.successInfo.duplicatesContactsEmail}]`,
            );
          } else {
            showSnackbarMessage(
              result.data?.uploadContacts?.error?.friendlyMessage ||
                'The contacts were not uploaded. Please check all of your rows comply with the provided format',
            );
          }
        })
        .catch((err) => {
          showSnackbarMessage(
            `File upload error. Please refresh the browser and try again.`,
          );
        });
    } else {
      showSnackbarMessage('There is still some data left to fill.');
    }
    event.preventDefault();
    return false;
  };

  // @ts-ignore
  return (
    <>
      {isDesktopScreen ? (
        <Box className={classes.notAvailable}>
          <Typography>
            This screen in only available for Desktop or tables
          </Typography>
        </Box>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={3}>
              <Box className={classes.formTitle}>
                <Typography variant="h5" align="left">
                  Upload Contacts
                </Typography>
                <Typography variant="body1">
                  If you don't have the template, <br />
                  download it &nbsp;
                  <a href="/csv_upload_contacts_template.csv" download>
                    here
                  </a>
                </Typography>

                <Accordion
                  style={{
                    background: '#6699CD',
                    borderRadius: 5,
                    marginTop: 10,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      style={{ flexDirection: 'row', display: 'inline-flex' }}
                    >
                      <InfoIcon style={{ color: '#000', marginRight: 5 }} />
                      <Typography
                        variant="body1"
                        style={{ color: '#000', fontWeight: 'lighter' }}
                      >
                        Contact Type Codes
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body1"
                      style={{ color: '#000', marginLeft: 29, marginTop: -15 }}
                    >
                      Buyer=1
                      <br /> Seller=2
                      <br /> Other Agent=3
                      <br /> Lender=4
                      <br /> Escrow Officer=5
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              alignContent={'center'}
              justifyContent={'center'}
            >
              <form onSubmit={submitForm} className={classes.form}>
                <input
                  ref={inputFileRef}
                  style={{ paddingRight: 200, width: 300 }}
                  accept="text/csv"
                  id="uploadInput"
                  type="file"
                  required
                  onChange={onFileChange}
                />

                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  type="submit"
                  className={classes.button}
                  disabled={
                    errorMessage.length > 0 || csvFile === null || !csvFile
                  }
                >
                  Submit
                </Button>
              </form>
            </Grid>
            <Grid item xs={12} md={3} />
          </Grid>
          <br />
          <br />
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}>
              {contactsList && <ContactsTable contactsList={contactsList} />}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </>
      )}
    </>
  );
};

export default UploadContacts;
