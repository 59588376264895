import { makeStyles, createStyles, Theme, fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
    signoutBtn:{
        color:"#fff",
        backgroundColor:"#d8648c",
        [theme.breakpoints.down('sm')]: {
            margin:'-1px 0 0 20px ',
            padding: "2.5px 10px",
            fontSize: "8px",

        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
            margin:"0 10px 0 20px", 
            borderRadius:"10px", 
            border: "2px solid #d8648c",
            padding: "5px 40px",
            fontWeight: 600,
            fontSize: "11px",
        }
    }
}))

export default useStyles;