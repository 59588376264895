import { useQuery } from '@apollo/client';
import {
  CssBaseline,
  ThemeProvider,
  ThemeOptions,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Main from './components/templates/main';
import theme from './styles/theme';
import AuthMain from './components/templates/AuthMain';
import { IS_LOGGED_IN, QuerySnackbar } from './graphql/queries/cache';
import { snackbarOpen } from './graphql/cache-structure';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getAccessStripe } from './services/stripeService';

const jwtValid = (jwt: string): boolean => jwt !== null && jwt.length > 0;
let stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
);

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>();
  const jwt = localStorage.getItem('jwt') || '';
  // const [stripePromise, setStripePromise] = useState<Stripe | null>(null)

  const { data, error } = useQuery(IS_LOGGED_IN, {
    skip: loggedIn,
  });

  const { data: dataSnackbar } = useQuery(QuerySnackbar);

  // useEffect(() => {
  //   if(!stripePromise){
  //     getAccessStripe().then(async res=>{
  //       setStripePromise(await loadStripe(res.key));
  //   })
  //   }
  // })

  useEffect(() => {
    if (jwtValid(jwt)) {
      setLoggedIn(true);
    } else if (error) {
      console.log('Some error happened');
    } else if (data) {
      if (loggedIn === undefined) {
        setLoggedIn(data.isLoggedIn);
      }
    }
  }, [jwt, error, data, loggedIn, setLoggedIn]);

  const handleSnackbarClose = (
    _: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    snackbarOpen(false);
  };

  if (loggedIn === undefined) {
    //|| !stripePromise
    return null;
  }

  return (
    <>
      <Elements stripe={stripePromise}>
        <ThemeProvider theme={theme({} as ThemeOptions)}>
          <CssBaseline />
          <BrowserRouter>{loggedIn ? <AuthMain /> : <Main />}</BrowserRouter>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={snackbarOpen()}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon />
              </IconButton>
            }
            message={dataSnackbar.snackbarMessage}
            onClose={handleSnackbarClose}
            autoHideDuration={4000}
          />
        </ThemeProvider>
      </Elements>
    </>
  );
};

export default App;
