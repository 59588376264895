import React, { useState } from 'react';
import { AppBar, Toolbar, Grid, IconButton, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from './styles';

export interface HeaderProps {
  children?: React.ReactNode
  leftSectionContent?: React.ReactNode
  mobileDrawerContent: React.ReactNode
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { children, leftSectionContent, mobileDrawerContent } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' && (
        (event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift'
      )
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar position="static" >
        <Toolbar>
          <Grid container alignItems='center'>
            {/* <Grid item xs={1}>
              <IconButton edge='start' color='inherit' aria-label='menu' onClick={toggleDrawer(true)} className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </Grid> */}
            <Grid item xs={leftSectionContent ? 10 : 11} sm={8} container direction="column" spacing={1}>
              { children }
            </Grid>
            <Grid item xs={leftSectionContent? 1 : false} sm={3}>
              { leftSectionContent }
            </Grid>
            <Grid item xs={1} >
              <IconButton edge='start' color='inherit' aria-label='menu' onClick={toggleDrawer(true)} className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        { mobileDrawerContent }
      </Drawer>
    </>
  )
}

export default Header;