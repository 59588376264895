import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import {useStyles} from './styles'

interface IPaymentPanelDetailProps{
    amount: number,
    planType?: string
}

export const PaymentPanelDetail:React.FC<IPaymentPanelDetailProps> = (props:IPaymentPanelDetailProps) => {
    const {amount} = props;
    const tax = amount * 0.23
    const total = amount;
    const classes = useStyles();
    return(
        <Paper className={classes.root}>
            <Grid className={classes.topContainer}>
                <Typography variant='h6' className={classes.title}>YOUR PLAN</Typography>
                <Grid container direction='row'>
                    <Grid item xs={6}>
                    <Typography variant='h6' className={classes.titleBlue}>Monthly plan</Typography>
                    <Typography variant='caption' className={classes.termsText}>Recurring billing, cancel anytime.</Typography><br/>
                    <Typography variant='caption' className={classes.termsTextLink}>Subscription Terms apply</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems='flex-end' >
                        <Typography className={classes.titlePriceBlue} variant='h6'>${amount}/month</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.bottomContainer}>
                {/* <Grid container direction='row' >
                    <Grid item xs={6} alignItems='flex-end' >
                        <Typography className={classes.textLabels} variant='h6'>Subtotal</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems='flex-end' >
                        <Typography className={classes.textNumbers} variant='h6'>${amount}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction='row' >
                    <Grid item xs={6} alignItems='flex-end' >
                        <Typography className={classes.textLabels} variant='h6'>Tax</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems='flex-end' >
                        <Typography className={classes.textNumbers} variant='h6'>${tax.toFixed(2)}</Typography>
                    </Grid>
                </Grid> */}
                <Grid container direction='row' alignItems='baseline'>
                    <Grid item xs={6} alignItems='flex-end' >
                        <Typography className={classes.textLabels} variant='h5'>Total</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems='flex-end' >
                        <Typography className={classes.textNumbers} variant='h4'>${total.toFixed(2)}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Typography></Typography>
            
        </Paper>
    )

}