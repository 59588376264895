import gql from "graphql-tag";

export const PaySubscription = gql`
  mutation ($token: String, $zipCode: String) {
    paySubscription(token: $token, zipCode: $zipCode) {
      success
      error {
        friendlyMessage
      }
    }
  }
`;

export const AddPaymentMethod = gql`
  mutation ($token: String, $zipCode: String) {
    addPaymentMethod(token: $token, zipCode: $zipCode) {
      success
      error {
        friendlyMessage
      }
    }
  }
`;

export const GetCharges = gql`
  query {
    getCharges {
      paymentMethods {
        id
        stripe_id
        type
        last_four
        card_brand
        account_id
      }
      charges {
        id
        charge_id
        amount
        payment_type
        card_type
        last_four
        status
        stripe_payment_method_id
      }
    }
  }
`;

export const CancelSubscription = gql`
  mutation ($userId: Int) {
    cancelSubscription(userId: $userId) {
      status
      message
    }
  }
`;

export const ActivateSubscription = gql`
  mutation ($userId: Int) {
    activateSubscription(userId: $userId) {
      status
      message
    }
  }
`;

export const DeleteAccount = gql`
  mutation {
    deleteUser {
      success
      error {
        friendlyMessage
      }
    }
  }
`;
