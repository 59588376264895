import React from 'react';
import Popper from '@material-ui/core/Popper';
import { IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import cardInfo from '../../../img/cvc-card-info.png';

export default function PopupCvc() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  console.log('event.currentTarget ',anchorEl)

  const open = Boolean(anchorEl);
  const id = open ? 'ccv-popover' : undefined;

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick} edge="end">
            <Info/>
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom-end'>
        <img src={cardInfo} alt="cvc-info" style={{width:'400px'}}  />
      </Popper>
    </div>
  );
}