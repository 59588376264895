import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbarTitle: {
    [theme.breakpoints.down('xs')]: {
      margin:'0 0 0 10px'
    },
    [theme.breakpoints.up('md')]: {
      margin:'0 0 0 100px',
      flex: 1,
    }
  },
  containerUserDetail:{
    flex:1,
    
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        flexDirection:'row',
    }
  },
  primaryContrast: {
    color: theme.palette.primary.contrastText
  },
  brandSubtitle: {
    marginLeft: 40
  },
  subNavBar: {
    '& > *': {
      padding: theme.spacing(0, 2)
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  buttonWithIcon: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        flexDirection:'row',
        fontSize:'12px',
        marginRight:'20px',
    },
    // alignSelf:'rigth',
    '& > span': {
      display: "flex",
      flexDirection: "column",
    }
  },
  logoApp:{
    width:"40px",
    height:"40px"
  }
}));
export default useStyles;
