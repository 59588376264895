import React from 'react';
import { Button, Card, CardActions, CardContent, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import useStyles from './styles';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { RequestResetPassword  } from '../../../shared/graphql/queries/user';
import { showSnackbarMessage } from '../../../graphql/cache-structure';

const ForgotMyPassword: React.FC = () => {
  const classes = useStyles();
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required('This field is required')
  });

  const [requestResetPassword] = useMutation(RequestResetPassword);

  const {
    handleSubmit, errors, handleChange, values
  } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      requestResetPassword({
        variables: {email: values.email}
      }).then(result => {
        showSnackbarMessage('Please check your email for instructions on resetting your password');
      }).catch(err => {
        console.log("Error" , err);
        showSnackbarMessage('Please check your email for instructions on resetting your password');
      })
    }
  })

  return (
    <Grid container>
      <Grid item xs={1} md={3} lg={4} />
      <Grid item xs={10} md={6} lg={4}>
        <Card>
          <form onSubmit={ handleSubmit }>
            <CardContent>
              <Typography variant="h5" align="center">
                Forgot your password?
              </Typography>
              <Typography variant="body1">
                Please, introduce your email and we will send you a reset password email.
              </Typography>
              <div className={classes.form}>
                <FormControl>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.email}
                    error={!!errors.email}
                    helperText={errors.email}
                    type="email" />
                </FormControl>
              </div>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Link to="/login">
                    Return to login
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button color='primary' variant="contained" size="large" type="submit">
                Send email
              </Button>
            </CardActions>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ForgotMyPassword;