import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { head } from 'lodash';

const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbarTitle: {
    flex: 1,
    padding:"10px 0",
    [theme.breakpoints.down('xs')]: {
      // display: 'none'
    }
  },
  primaryContrast: {
    color: theme.palette.primary.contrastText
  },
  brandSubtitle: {
    marginLeft: 40
  },
  subNavBar: {
    '& > *': {
      padding: theme.spacing(0, 2)
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  buttonWithIcon: {
    '& > span': {
      display: "flex",
      flexDirection: "column"
    }
  },
  logoApp:{
    width:"40px",
    height:"40px",
    [theme.breakpoints.down('xs')]: {
      marginLeft:10,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft:200
    }
  },
  buttonContainer:{
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  }
}));
export default useStyles;
