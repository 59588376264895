import { InMemoryCache, makeVar } from '@apollo/client';

export const origin = makeVar('');
export const isLoggedIn = makeVar(false);
export const snackbarOpen = makeVar(false);
export const snackbarMessage = makeVar('');
export const showSnackbarMessage = (message: string) => {
  snackbarMessage(message);
  snackbarOpen(true);
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedIn();
          },
        },
        snackbarOpen() {
          return snackbarOpen();
        },
        snackbarMessage() {
          return snackbarMessage();
        },
        origin() {
          return origin();
        }
      },
    },
  },
});

export default cache;
