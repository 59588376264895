import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  TableCell,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  MeQuery,
  ListAllUsers,
  ListAdditionalInfo,
} from '../../../shared/graphql/queries/user';
import { UploadOpportunities } from '../../../shared/graphql/queries/opportunity';
import { ISafeUser } from '../../../shared-global/interfaces/other/safe-user.interface';
import { ILeadSource } from '../../../shared-global/interfaces/models/lead-source.interface';
import { InitialData } from '../../../shared/interfaces';
import { IStatus } from '../../../shared-global/interfaces/models/status.interface';
import useStyles from './styles';
import { showSnackbarMessage } from '../../../graphql/cache-structure';

const UploadOpportunitiesCsv: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const [ready, setReady] = useState(false);
  const [users, setUsers] = useState<ISafeUser[]>([]);
  const [leadSources, setLeadSources] = useState<ILeadSource[]>([]);
  const [statuses, setStatuses] = useState<IStatus[]>([]);
  const [csvFile, setCsvFile] = useState<File>();
  const [selectedUser, setSelectedUser] = useState<ISafeUser>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { data: dataMe, loading: loadingMe } = useQuery(MeQuery);
  const { data: dataAllUsers, loading: loadingAllUsers } =
    useQuery(ListAllUsers);
  const { data: additionalInfo, loading: additionalLoading } = useQuery(
    ListAdditionalInfo,
    {
      variables: { user_id: selectedUser ? selectedUser.id : null },
    },
  );

  const [uploadOpportunitiesMutation] = useMutation(UploadOpportunities);

  // Submit form should send the multipart form with the file and any extra data required
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    if (selectedUser && csvFile) {
      uploadOpportunitiesMutation({
        variables: {
          file: csvFile,
          user_id: selectedUser?.id,
        },
      })
        .then((result) => {
          if (result.data.uploadOpportunities.success) {
            showSnackbarMessage('Opportunities successfully uploaded');
          } else {
            showSnackbarMessage(
              result.data?.uploadOpportunities?.error?.friendlyMessage ||
                'The opportunities were not uploaded. Please check all of your rows comply with the provided format',
            );
          }
        })
        .catch((err) => {
          showSnackbarMessage(
            'Use commas as separator and make sure the date formate is MM/DD/YYYY',
          );
        });
    } else {
      showSnackbarMessage('There is still some data left to fill.');
    }
    event.preventDefault();
    return false;
  };

  useEffect(() => {
    if (!loadingMe && dataMe) {
      // console.log('>> ', dataMe);
      if (dataMe?.me?.role?.toString() === 'system_admin') {
        setReady(true);
      } else {
        history.push('/insights');
      }
    }
  }, [loadingMe, dataMe, setReady, history]);

  useEffect(() => {
    if (!loadingAllUsers && dataAllUsers) {
      setUsers(dataAllUsers.listAllUsers);
    }
  }, [loadingAllUsers, dataAllUsers, setUsers, setSelectedUser]);

  // Be aware of the additional info
  useEffect(() => {
    if (!additionalLoading && additionalInfo) {
      const { status, leadSources: newLeadSources } =
        additionalInfo.listAdditionalInfoByUser as InitialData;
      setStatuses(status || []);
      setLeadSources(newLeadSources || []);
    }
  }, [additionalInfo, additionalLoading, setStatuses, setLeadSources]);

  const handleSelectChange = (
    event: React.ChangeEvent<{}>,
    value: ISafeUser | null,
    _: AutocompleteChangeReason,
  ) => {
    if (value) {
      setSelectedUser(value);
    }
  };

  const onFileChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    setErrorMessage('');
    const filename = file.name as string;
    const extension = filename.substring(filename.lastIndexOf('.') + 1);
    if (validity.valid && extension === 'csv') {
      setCsvFile(file);
    } else {
      setErrorMessage('The file you uploaded is not a CSV.');
    }
  };

  if (ready && users) {
    return (
      <Grid container>
        <Grid item xs={1} md={3} lg={4} />
        <Grid item xs={10} md={6} lg={4}>
          <Card>
            <form onSubmit={submitForm}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" align="center">
                  Upload opportunities
                </Typography>
                <Typography variant="body1">
                  If you don't have the template, download it &nbsp;
                  <a href="/csv_template.csv" download>
                    here
                  </a>
                </Typography>
                <div className={classes.form}>
                  <Autocomplete
                    onChange={handleSelectChange}
                    id="user-select-automcomplete"
                    options={users}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a user"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  <input
                    accept="text/csv"
                    id="uploadInput"
                    type="file"
                    required
                    onChange={onFileChange}
                  />
                  {selectedUser ? (
                    <Grid container>
                      <Grid item md={6} xs={12} container direction="column">
                        <Typography variant="body1">
                          Remember, your status MUST be among the following
                          values:
                        </Typography>
                        <List>
                          {statuses.map((status) => (
                            <ListItem key={status.id}>
                              <ListItemText primary={status.name} />
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                      <Grid item md={6} xs={12} container direction="column">
                        <Typography variant="body1">
                          Remember, your lead source MUST be among the following
                          values:
                        </Typography>
                        <List>
                          {leadSources.map((leadSource) => (
                            <ListItem key={leadSource.id}>
                              <ListItemText primary={leadSource.name} />
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  ) : null}
                  {errorMessage.length > 0 ? (
                    <Typography variant="body1" className={classes.danger}>
                      {errorMessage}
                    </Typography>
                  ) : null}
                </div>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={
                    errorMessage.length > 0 || !selectedUser || !csvFile
                  }
                >
                  Submit
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default UploadOpportunitiesCsv;
