import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

export interface NotFoundProps {
  loggedIn: boolean
}

const NotFound: React.FC<NotFoundProps> = (props: NotFoundProps) => {
  const { loggedIn } = props;
  return (
    <Grid container>
      <Grid item xs={false} md={3} />
      <Grid item xs={12} md={6}>
        <Typography variant="h4" align="center">
          Are you lost?
        </Typography>
        <Typography variant="h6">
          We could not find the page you were looking for. Try with:
        </Typography>
        {
          loggedIn
            ? (
              <>
                <Link to="/insights">
                  <Typography variant="body1">
                    Insights
                  </Typography>
                </Link>
                <Link to="/sign_up">
                  <Typography variant="body1">
                    Home
                  </Typography>
                </Link>
              </>
            ) : (
              <>
                <Link to="/login">
                  <Typography variant="body1">
                    Login
                  </Typography>
                </Link>
                <Link to="/sign_up">
                  <Typography variant="body1">
                    Sign up
                  </Typography>
                </Link>
                <Link to="/">
                  <Typography variant="body1">
                    Home
                  </Typography>
                </Link>
              </>
            )
        }
      </Grid>
    </Grid>
  );
};

export default NotFound;
