import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  cardActions: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  cardContent: {
    '& > *': {
      marginTop: 16
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    '& > *': {
      width: "100%",
      marginTop: 16
    }
  }
}));
export default useStyles;
