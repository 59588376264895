import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {Typography, Box, Grid, Button} from '@material-ui/core';
import { MeQuery } from '../../../shared/graphql/queries/user';
import useStyles from './styles';

export const BoxUserDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: dataMe } = useQuery(MeQuery);
  const [userName, setUserName] = React.useState(null)
  const [dataloaded, setDataLoaded] = React.useState(false)

  const logoutAction = () =>{
    localStorage.setItem("jwt", '');
    localStorage.setItem("refresh_token", '');
    history.replace("/")
    window.location.reload();
  }

  React.useEffect(()=>{
    if(!dataloaded && dataMe && dataMe.me ){
      setUserName(dataMe.me.firstName)
      setDataLoaded(true)
    }
  })

  if(userName){
    return <Grid container direction="row" alignItems='center'>
              <Typography variant="inherit">Hi, {userName}!</Typography>
              <Button variant="contained" onClick={logoutAction} color="secondary"  className={classes.signoutBtn}>Sign out</Button>
          </Grid>
  }else{return <></>}

};