//import createMuiTheme, { ThemeOptions, Theme } from '@material-ui/core/styles/createMuiTheme';
import { createMuiTheme, ThemeOptions, Theme } from '@material-ui/core/styles';
import './createMuiTheme';

export default function createMyTheme(options: ThemeOptions): Theme {
  return createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#000', //'#3BAD87',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#d8648c', //'#e57373',
        contrastText: '#FFF',
      },
      tertiary: {
        main: '#FF7D5C',
        contrastText: '#FFF',
      },
      quaternary: {
        main: '#F3A674',
        contrastText: '#FFF',
      },
      success: {
        main: '#11A124',
      },
    },
    typography: {
      danger: {
        color: '#555',
      },
      body1: {
        color: '#8D8D8D',
      },
      h4: {
        color: '#555555',
      },
      fontFamily: ['Lato'].join(','),
    },
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The default props to change
        disableRipple: false, // No more ripple, on the whole application 💣!
        focusRipple: false,
      },
    },
    ...options,
  });
}
