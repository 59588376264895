import { createStyles, Theme, makeStyles } from '@material-ui/core';

const useStyles = (size?: number) => makeStyles((theme: Theme) => createStyles({
  avatar: {
    [theme.breakpoints.up('xs')]: {
      width: size || 150,
      height: size || 150
    },
    [theme.breakpoints.up('md')]: {
      width: size || 150,
      height: size || 150
    },
    [theme.breakpoints.up('lg')]: {
      width: size || 200,
      height: size || 200
    },

  }
}))();
export default useStyles;