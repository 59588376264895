import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  cardContent: {
    '& > *': {
      marginTop: 16,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      width: '100%',
      marginTop: 16,
    },
  },
  danger: {
    color: theme.palette.error.main,
  },
}));
export default useStyles;
