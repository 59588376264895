import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Login from '../pages/Login/Login';
import NoAuthHeader from '../organisms/no-auth-header/NoAuthHeader';
import NotFound from '../pages/not-found/NotFound';
import Index from '../pages/index/Index';
import SignUp from '../pages/sign-up/SignUp';
import RespondAccessRequest from '../pages/respond-access-request/RespondAccessRequest';
import ForgotMyPassword from '../pages/forgot-my-password/ForgotMyPassword';
import ResetPassword from '../pages/reset-password/ResetPassword';

const Main: React.FC = () => (
  <>
    <NoAuthHeader />
    <div style={{ marginTop: 16 }}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/sign_up" component={SignUp} />
        <Route exact path="/account/respond_to_access_request" component={RespondAccessRequest} />
        <Route exact path="/forgot_my_password" component={ForgotMyPassword} />
        <Route path="/user/reset_password/:token" component={ResetPassword} />
        <Route exact path="/" component={Login} />
        <Route path="*">
          <NotFound loggedIn={false} />
        </Route>
      </Switch>
    </div>
  </>
);

export default Main;
