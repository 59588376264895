import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthHeader from '../organisms/auth-header/AuthHeader';
// import Dashboard from '../pages/dashboard/Dashboard';
// import Groups from '../pages/groups/Groups';
import NotFound from '../pages/not-found/NotFound';
import Index from '../pages/index/Index';
import RespondAccessRequest from '../pages/respond-access-request/RespondAccessRequest';
import UploadOpportunitiesCsv from '../pages/upload-opportunities-csv/UploadOpportunitiesCsv';
import { Profile } from '../pages/profile/Profile';
// import GroupDetail from '../pages/group-detail/GroupDetail';
import DownloadDeals from '../pages/download-deals/DownloadDeals';
import { SubscriptionPage } from '../pages/subscription/SubscriptionPage';
import UploadContacts from '../pages/uploadContacts/UploadContacts';

const AuthMain: React.FC = () => (
  <>
    <AuthHeader />
    <div style={{ marginTop: 16 }}>
      <Switch>
        <Route exact path="/mydeals" component={DownloadDeals} />
        <Route exact path="/upload-contacts" component={UploadContacts} />
        {/* <Route exact path="/groups" component={Groups} /> */}
        {/* <Route exact path="/group/:id" component={GroupDetail} /> */}
        {/* <Route exact path="/insights" component={Dashboard} /> */}
        {/* <Route exact path="/account/respond_to_access_request" component={RespondAccessRequest} /> */}
        <Route exact path="/subscription" component={SubscriptionPage} />
        <Route exact path="/profile" component={Profile} />
        <Route
          exact
          path="/upload_opportunities_csv"
          component={UploadOpportunitiesCsv}
        />
        <Route exact path="/" component={DownloadDeals} />
        <Route path="*">
          <NotFound loggedIn />
        </Route>
      </Switch>
    </div>
  </>
);

export default AuthMain;
