import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root:{
    backgroundColor:'#6699cd',
    marginTop:'-16px',
    paddingTop:'50px'
  },
  gridContainer: {
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
      '& > *': {
        padding: theme.spacing(2, 0)
      }
    },
    [theme.breakpoints.up('md')]: {
      '& > *': {
        padding: theme.spacing(0, 2)
      }
    },
  },
  input: {
    display: 'none',
  },
  rootElement: {
    display: 'flex',
    alignItems: 'center',
  },
  labelContainer: {
    flex: 1,
  },
  spanFilename: {
    flex: '0 0 30%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 16,
    paddingRight: 16,
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  cardContent: {
    '& > *': {
      marginTop: 16,
    },
  }
}));
export default useStyles;
