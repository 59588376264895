import gql from 'graphql-tag';

export const CREATE_OPPORTUNITY = gql`
  mutation (
    $opportunity: OpportunityInputCreate!
    $contacts: [ContactInputUpsert]
    $events: [EventInputCreate]
    $interests: [Int]
  ) {
    createOpportunity(
      opportunity: $opportunity
      contacts: $contacts
      events: $events
      interests: $interests
    ) {
      opportunity {
        id
        opportunityName
        opportunityAddress
        opportunityCity
        opportunityState
        opportunityZip
        companyName
        opportunityValue
        flatFee
        commissionPercent
        commissionType
        commissionCompanySplit
        type
        statusId
        lastStatusChanged
        leadSourceId
        createdDate
        closeDate
        notes
        occurrenceInterval
        occurrenceType
        touchPoints
        interestToOpportunities {
          interestId
          opportunityId
        }
        status {
          id
          name
          defaultId
        }
        contacts {
          id
          firstName
          lastName
          phone
          email
          address
          city
          state
          zip
          primary
          contactTypeId
          userId
          phoneId
        }
        events {
          id
          date
          eventTypeId
          opportunityId
        }
      }
      contacts {
        id
        firstName
        lastName
        phone
        email
        zip
        address
        city
        state
        phoneId
      }
      events {
        id
        date
        eventTypeId
        opportunityId
      }
    }
  }
`;

export const UPDATE_OPPORTUNITY = gql`
  mutation updateOpportunity(
    $opportunity: OpportunityInputUpdate!
    $contacts: [ContactInputUpsert]
    $events: [EventInputUpsert]
    $interests: [Int]
  ) {
    updateOpportunity(
      opportunity: $opportunity
      contacts: $contacts
      events: $events
      interests: $interests
    ) {
      opportunity {
        id
        opportunityName
        opportunityAddress
        opportunityCity
        opportunityState
        opportunityZip
        companyName
        opportunityValue
        commissionPercent
        commissionType
        status {
          id
          name
          defaultId
        }
        leadSource {
          id
          name
        }
        flatFee
        commissionCompanySplit
        type
        statusId
        lastStatusChanged
        leadSourceId
        createdDate
        closeDate
        notes
        occurrenceInterval
        occurrenceType
        touchPoints
        contactToOpportunities {
          id
          contactId
          contactTypeId
        }
        contacts {
          id
          firstName
          lastName
          phone
          email
          address
          city
          state
          zip
          primary
          contactTypeId
          userId
        }
        events {
          id
          date
          eventTypeId
          opportunityId
        }
      }
      interests
      activity {
        id
        message
        indicator
        read
        user {
          id
          firstName
          lastName
          email
          password
          settings {
            data {
              name
              value
            }
          }
        }
        createdDate
      }
    }
  }
`;

export const VIEW_OPPORTUNITY = gql`
  query opportunity($opportunityId: Int!) {
    opportunity(id: $opportunityId) {
      id
      opportunityName
      opportunityAddress
      opportunityCity
      opportunityState
      opportunityZip
      companyName
      opportunityValue
      commissionType
      flatFee
      commissionPercent
      commissionCompanySplit
      type
      statusId
      closeDate
      notes
      switchTimer
      touchPoints
      occurrenceType
      occurrenceInterval
      interestToOpportunities {
        interestId
        opportunityId
      }
      contactToOpportunities {
        id
        contactId
        contactTypeId
      }
      status {
        id
        name
        defaultId
      }
      leadSource {
        id
        name
      }
      contacts {
        id
        firstName
        lastName
        phone
        email
        city
        state
        address
        zip
        contactTypeId
      }
      events {
        id
        date
        eventTypeId
      }
      totalCycles
      nextCycleDate
    }
  }
`;

export const MyOpportunities = gql`
  query myOpportunities {
    myOpportunities {
      id
      opportunityName
      opportunityAddress
      opportunityCity
      opportunityState
      opportunityZip
      type
      companyName
      opportunityValue
      commissionType
      flatFee
      commissionPercent
      commissionCompanySplit
      commissionType
      statusId
      leadSourceId
      closeDate
      createdDate
      notes
      switchTimer
      cyclesMisses
      touchPoints
      occurrenceType
      occurrenceInterval
      touchPointCount
      interestToOpportunities {
        interestId
        opportunityId
      }
      leadSource {
        id
        name
      }
      contacts {
        id
        firstName
        lastName
        phone
        email
        city
        state
        address
        zip
        contactTypeId
      }
      events {
        id
        eventTypeId
        opportunityId
        date
        pushNotificationSent
      }
      contactToOpportunities {
        id
        contactId
        contactTypeId
      }
      lastStatusChanged
      status {
        id
        name
        rank
        defaultId
      }
      totalCycles
      nextCycleDate
    }
  }
`;

export const GET_MY_OPPORTUNITIES_BY_STATUS = gql`
  query myOpportunitiesByStatusId($statusId: Int) {
    myOpportunitiesByStatusId(statusId: $statusId) {
      id
      opportunityName
      opportunityAddress
      opportunityCity
      opportunityState
      opportunityZip
      companyName
      opportunityValue
      commissionPercent
      flatFee
      commissionType
      commissionCompanySplit
      statusId
      closeDate
      createdDate
      notes
      switchTimer
      occurrenceInterval
      occurrenceType
      touchPoints
      status {
        id
        name
        defaultId
      }
      leadSource {
        id
        name
      }
      contactToOpportunities {
        id
        contactId
        contactTypeId
      }
      contacts {
        id
        firstName
        lastName
        phone
        email
        city
        state
        address
        zip
        contactTypeId
      }
      events {
        id
        eventTypeId
        opportunityId
        pushNotificationSent
      }
      totalCycles
      nextCycleDate
    }
  }
`;

export const UPDATE_OPPORTUNITY_STATUS = gql`
  mutation updateOpportunityStatus($id: Int!, $statusId: Int!) {
    updateOpportunityStatus(id: $id, statusId: $statusId) {
      opportunity {
        id
        opportunityName
        opportunityAddress
        opportunityCity
        opportunityState
        opportunityZip
        companyName
        opportunityValue
        commissionPercent
        commissionCompanySplit
        lastStatusChanged
        type
        statusId
        leadSource {
          id
          name
        }
        status {
          id
          name
          defaultId
        }
        closeDate
        notes
        occurrenceInterval
        occurrenceType
        touchPoints
      }
    }
  }
`;

export const UploadOpportunities = gql`
  mutation ($file: Upload!, $user_id: Int!) {
    uploadOpportunities(file: $file, user_id: $user_id) {
      success
      error {
        recordId
        friendlyMessage
        errorCode
      }
    }
  }
`;

export const resetCycleTimer = gql`
  mutation resetFollowerTimer($id: Int!) {
    resetFollowerTimer(id: $id) {
      id
    }
  }
`;

export const resetMultipleCycleTimer = gql`
  mutation resetMultiplesFollowerTimer($ids: [Int]!) {
    resetMultiplesFollowerTimer(ids: $ids) {
      id
      opportunityName
    }
  }
`;

export const filterOpportunitiesByInterestId = gql`
  query opportunitiesPerInterest($interestId: Int!) {
    opportunitiesPerInterest(interestId: $interestId) {
      id
      opportunityName
      opportunityAddress
      opportunityCity
      opportunityState
      opportunityZip
      type
      companyName
      opportunityValue
      commissionType
      flatFee
      commissionPercent
      commissionCompanySplit
      commissionType
      statusId
      leadSourceId
      closeDate
      createdDate
      notes
      switchTimer
      cyclesMisses
      touchPoints
      occurrenceType
      occurrenceInterval
      touchPointCount
      interestToOpportunities {
        interestId
        opportunityId
      }
      leadSource {
        id
        name
      }
      contacts {
        id
        firstName
        lastName
        phone
        email
        city
        state
        address
        zip
        contactTypeId
      }
      events {
        id
        eventTypeId
        opportunityId
        date
      }
      contactToOpportunities {
        id
        contactId
        contactTypeId
      }
      lastStatusChanged
      status {
        id
        name
        rank
        defaultId
      }
      totalCycles
      nextCycleDate
    }
  }
`;
