import { useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress,Popper, FormControl, FormHelperText, Grid, TextField, Typography,Box, Select, MenuItem, IconButton, Popover } from '@material-ui/core';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
// import { StripeCardElementChangeEvent,StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { origin, showSnackbarMessage } from '../../../graphql/cache-structure';
import { PAYMENT_STATUS } from '../../../shared-global/enums/account.enums';
import { ISafeUser } from '../../../shared-global/interfaces/other/safe-user.interface';
import { PaySubscription, AddPaymentMethod as AddPaymentMethodQuery } from '../../../shared/graphql/queries/account';
import { MeQuery, RemoteLog } from '../../../shared/graphql/queries/user';
import {GetAllPromocodes, UpdatePromotionCodeRedemption} from '../../../shared/graphql/queries/promotionCode';
import useStyles from './styles';
import { Lock, Info } from '@material-ui/icons';
import { PROMOTION_CODE_ERROR_MESSAGE,validateRegistrationPromotionCodes} from '../../../shared-global/utils/registrationUtils';
import { PopupAlert } from '../../molecules/popup-alert/PopupAlert';
import { useHistory } from 'react-router';
import { PaymentPanelDetail } from '../payment-panel-detail/PaymentPanelDetail';
import cardInfo from '../../../img/cvc-card-info.png';
import PopupCvc from '../../atoms/popup-cvc/PopupCvc';



export interface AddPaymentMethodProps {
  me: ISafeUser,
  refetchMe?: any
}

export const AddPaymentMethodForm: React.FC<AddPaymentMethodProps> = (props: AddPaymentMethodProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { me, refetchMe } = props;
  const {data:codesData} = useQuery(GetAllPromocodes)

  const isUnpaid = me.account?.paymentStatus !== PAYMENT_STATUS.PAID;
  const [codeErrorMessage, setCodeErrorMessage] = useState('');
  const [cardError, setCardError] = useState<any | null>(null);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [remoteLog] = useMutation(RemoteLog);
  const [paySubscription] = useMutation(PaySubscription, {
    update(cache, { data }) {
      const success = data?.paySubscription?.success;

      if (success) {
        cache.writeQuery({
          query: MeQuery,
          data: {
            me: {
              account: {
                paymentStatus: 'PAID'
              }
            }
          }
        })
      } else {
        showSnackbarMessage("Some error happened while making the payment. Please, contact with an administrator");
      }
    }
  });

  const [addPaymentMethod] = useMutation(AddPaymentMethodQuery);
  const [updatePromotionCode] = useMutation(UpdatePromotionCodeRedemption);
  // const [zipCodeRestrictionMessage, setZipCodeRestrictionMessage] = useState(false);
  // const [cardholder, setCardholder] = useState({firstname:'',lastname:''})
  // const [cardType, setCardType] = useState(0);
  const [cardDetails, setCardDetails] = useState({
    number: false,
    expiry: false,
    cvc: false
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [token, setToken] = useState<any | null>(null);
  const [zipCode, setZipCode] = useState<any | null>(null);
  const [isDisabledBtnCode, setIsDisabledBtnCode] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(()=>{
    validateFormSubcription()
    // validateZipcode(zipCode)?setZipCodeRestrictionMessage(false):setZipCodeRestrictionMessage(true);
  },[cardDetails])//,cardholder,zipCode,cardType])

  const resetForm = () => {
    setCardDetails({
      number: false,
      expiry: false,
      cvc: false
    })
    // setCardholder({firstname:'',lastname:''})
    // setZipCode('')
    // setCardType(0)
  }

  const handleCardFields = (element:any) => {
    switch(element.elementType){
      case 'cardNumber': setCardDetails({...cardDetails, number:element.complete});return;
      case 'cardExpiry': setCardDetails({...cardDetails, expiry:element.complete});return;
      case 'cardCvc': setCardDetails({...cardDetails, number:element.complete});return;
      default: console.log('no existing field!')
    }
  }
  // const handleChangeFields = (e:any) => {
  //   switch(e.target.name){
  //     case 'typeCard': setCardType(e.target.value);return;
  //     case 'firstname': setCardholder({...cardholder,firstname:e.target.value});return;
  //     case 'lastname': setCardholder({...cardholder,lastname:e.target.value});return;
  //     case 'zipcode': setZipCode(e.target.value);return;
  //     default: return;
  //   }
  // };

  const validateFormSubcription = () => {
    const possibleNumberElement = elements?.getElement(CardNumberElement);
    if (possibleNumberElement) {
      stripe?.createToken(possibleNumberElement).then(token => {
        if(token?.token?.id){// && cardholder.firstname!=='' && cardholder.lastname!=='' && validateZipcode(zipCode) && cardType>0){
          setToken(token?.token?.id);
          setSubmitting(true);
        }else{
          setSubmitting(false)
        }
      });
    }else{
      setSubmitting(false)
    }
  }

  const onPaySubscription = async() => {
    if(!isSubmitting){return}
    paySubscription({
      variables: {
        token,
        zipCode
      }
    }).then(result => {
      if(result.data.paySubscription.success){
        resetForm();
        elements?.getElement(CardNumberElement)?.clear();
        elements?.getElement(CardCvcElement)?.clear();
        elements?.getElement(CardExpiryElement)?.clear();
        history.push('/profile');
      }
      setSubmitting(false);
      const cachedOrigin = origin();
      remoteLog({
        variables: {
          message: `origin ${cachedOrigin}`,
        }
      });
      if (cachedOrigin) {
        window.location.href = `${process.env.REACT_APP_DEEP_LINK_HOST}`;
      }
    }).catch(err => {
      setSubmitting(false);
    })
  };

  // const onAddPaymentMethod = () => {
  //   setSubmitting(true);
  //   addPaymentMethod({
  //     variables: {
  //       token,
  //       zipCode
  //     },
  //     refetchQueries: [{ query: GetCharges }],
  //     awaitRefetchQueries: true
  //   }).then((result) => {
  //     console.log('result add card: ',result)
  //     if(result.data.addPaymentMethod.error){
  //       alert(result.data.addPaymentMethod.error.friendlyMessage)
  //     }
  //     setSubmitting(false);
  //   }).catch(err => {
  //     console.log('result with error: ',err)
  //     setSubmitting(false);
  //   })
  // }

  const validatePromotionCode = (valuesToSubmit:any) => {
    if(codesData&&codesData.getAllPromotionCodes){
      let found = false;
      codesData.getAllPromotionCodes.forEach((promoCode: { code: string; status: any; }) => {
        if(promoCode.code===valuesToSubmit.code && validateRegistrationPromotionCodes(promoCode.code) && promoCode.status){
          found = true;
          setCodeErrorMessage('');
          return;
        }
        else{
          setCodeErrorMessage(PROMOTION_CODE_ERROR_MESSAGE);
        }
      });
      found?setIsDisabledBtnCode(false):setIsDisabledBtnCode(true);
    }
  }

  const handleSubmitPromotioCode = (valuesToSubmit:any) => {
    if(valuesToSubmit.code==='' || !validateRegistrationPromotionCodes(valuesToSubmit.code)){
      return;
    }
    updatePromotionCode({
      variables:{
        promotioncode:valuesToSubmit.code
      }
    }).then(response => {
      if(response.data.updatePromotionCode.status){
        refetchMe();
        history.push('/profile');
      }
    })
  }

  const validationSchema = Yup.object().shape({
    code: Yup.string().required('This field is required')
  })

  const {
    handleSubmit, errors, handleChange, values
  } = useFormik({
    initialValues: { code: '' },
    validationSchema,
    validate: validatePromotionCode,
    onSubmit: handleSubmitPromotioCode
  });

  const open = Boolean(anchorEl);
  const id = open ? 'ccv-popover' : undefined;

  return (
    <Grid item container alignItems="center" className={classes.root}>
        <Grid item container direction="column" alignContent="center">
              <Box style={{textAlign:'center'}}>
              <Typography variant="h4" component="p" className={classes.title}> Sign up for a paid account</Typography>
              </Box>
        </Grid>

        {
          true && ( 
            <Grid item xs={12} direction="column">
              <Typography variant="body1" component="p" className={classes.promoTitle}>
                I have a promo code :
              </Typography><br/>
              <form onSubmit={handleSubmit} >
                <Grid direction="column"  spacing={2}  className={classes.promoForm}>
                  <Grid item >
                    <FormControl className={classes.controlForm}>
                      <TextField
                        id="code"
                        placeholder="Insert promo code"
                        variant="outlined"
                        onChange={handleChange}
                        value={values.code}
                        autoComplete="off"
                        />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button 
                      className={classes.btnCode}
                      color="primary" 
                      variant="contained" 
                      type="submit" 
                      disabled={isDisabledBtnCode} >
                      Accept
                    </Button>
                  </Grid>
                  <Grid item container direction="column" alignContent="center">
                      <Box style={{textAlign:'center',width:'250px',wordWrap:'normal'}}>
                        {
                          isDisabledBtnCode && values.code!==''?
                          <Typography variant='caption' className={classes.codeError} >
                            {codeErrorMessage}
                          </Typography>:<></>
                        }
                      </Box>
                  </Grid>
                </Grid>

              </form>
            </Grid>
          )
        }
        <Grid container className={classes.formWrapper} >
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
              <Typography variant='body2' className={classes.labelInput} >Payment method</Typography>
              <FormHelperText>
                {cardError ? cardError.message : null}
              </FormHelperText>
              {/* <FormControl variant="outlined" style={{width:'100%',backgroundColor:'#FFF',borderRadius:'5px'}} >
                <Select
                  name='typeCard'
                  value={cardType}
                  onChange={handleChangeFields}
                >
                  <MenuItem value={0}>
                    <em>Select payment method</em>
                  </MenuItem>
                  <MenuItem value={1}>Debit Card</MenuItem>
                  <MenuItem value={2}>Credit Card</MenuItem>
                </Select>
              </FormControl> */}
              <Typography variant='body2' className={classes.labelInput} >Card number</Typography>
              <FormControl variant="outlined" 
                style={{
                  width:'100%',
                  height:'50px',
                  backgroundColor:'#FFF',
                  borderRadius:'5px',
                  justifyContent:'center',
                  paddingLeft:'10px'
                  }} >
                <CardNumberElement options={{showIcon:true}} onChange={handleCardFields}/>
              </FormControl>
              <Grid container direction='row' spacing={2}  >
                <Grid item xs={6}>
                  <Typography variant='body2' className={classes.labelInput} >Expiration date</Typography>
                  <FormControl variant="outlined"
                      style={{
                      width:'100%',
                      height:'50px',
                      backgroundColor:'#FFF',
                      borderRadius:'5px',
                      justifyContent:'center',
                      paddingLeft:'10px'
                      }}
                   >
                    <CardExpiryElement onChange={handleCardFields} />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body2' className={classes.labelInput} >Security code</Typography>
                  <FormControl variant="outlined"
                    style={{
                      width:'100%',
                      height:'50px',
                      backgroundColor:'#FFF',
                      borderRadius:'5px',
                      justifyContent:'center',
                      flexDirection:'row'
                    
                      }}
                  >
                    <div className={classes.cvcWrapper}>
                      <CardCvcElement onChange={handleCardFields} />
                    </div>
                    <PopupCvc/>
                  </FormControl>
                </Grid>
              </Grid>
              
              {/* <Typography variant='body2' className={classes.labelInput} >First name</Typography>
              <FormControl variant="outlined"
                style={{
                  width:'100%',
                  backgroundColor:'#FFF',
                  borderRadius:'5px',
                  }}
              >
                <TextField name='firstname' value={cardholder.firstname} variant="outlined" placeholder='Enter first name' onChange={handleChangeFields} />
              </FormControl>
              <Typography variant='body2' className={classes.labelInput} >Last name</Typography>
              <FormControl variant="outlined"
                style={{
                  width:'100%',
                  backgroundColor:'#FFF',
                  borderRadius:'5px'
                  }}
              >
                <TextField name='lastname' value={cardholder.lastname} variant="outlined" placeholder='Enter last name' onChange={handleChangeFields} />
              </FormControl>
              <Typography variant='body2' className={classes.labelInput} >Zip code</Typography>
              <FormControl variant="outlined"
                style={{
                  width:'100%',
                  backgroundColor:'#FFF',
                  borderRadius:'5px',
                  }}
              >
                <TextField name='zipcode' 
                  value={zipCode} 
                  variant="outlined" 
                  placeholder='Enter zip code' 
                  onChange={handleChangeFields}/>
              </FormControl> */}
              <br/><br/>
              <PaymentPanelDetail amount={20.00}/>
              <Button 
                className={classes.btnCodePremium}
                color="primary" 
                variant="contained" 
                type="submit" 
                disabled={!isSubmitting}
                onClick={onPaySubscription}
                >
                Pay Subscription
              </Button>
              <Grid container direction='row' alignContent='center' alignItems='center' justifyContent='center'>
                <Grid item><Lock style={{color:'#FFF',marginTop:'13px',marginRight:'5px'}}/></Grid>
                <Grid item><Typography variant='body2' className={classes.labelSecureCheckout} >Secure checkout</Typography></Grid>
              </Grid>
              {/* <PopupAlert 
                isOpen={zipCodeRestrictionMessage} 
                title={"Zip code restriction"}
                message={RESTRICTED_ZIP_CODES_MESSAGE} 
                action={setZipCodeRestrictionMessage} /> */}
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
    </Grid>
  );
};