import * as React from 'react';
import Link from '@material-ui/core/Link';
import Button  from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { COLOR } from '../../../styles/colors';
import logo from '../../../img/DSicon.png';
import { styled } from '@material-ui/styles';

interface IPopupAlertCancelSubscription {
  actionProp?:any
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper':{
    borderRadius:15
  },
  '& .MuiDialogTitle-root':{
    fontWeight:'bolder',
    fontFamily:'Open Sans',
    fontSize:'20px',
    textAlign:'center',
    padding:'0px 40px 0 40px',
    marginBottom:'16px',
    color:COLOR.GRAYSCALE,
  }
}));

const useStyles = makeStyles((theme: Theme) => createStyles({
     dialog:{
       borderRadius:16
     },
    contexWrapper:{
      padding:'0px 40px'
    },
    logoWrapper:{
      alignSelf:'center'
    },
    logoApp:{
      width:"60px",
      height:"60px",
      borderRadius:16,
      textAlign:'center',
      margin:'24px 0 10px 0'
    },
    message:{
      textAlign:'center'
    },
    cancelSubcriptionLink:{
        [theme.breakpoints.down('sm')]: {
          marginTop:-30
        },
        color: COLOR.LINK_ACTION
    },
    buttonWrapper:{
      justifyContent:'space-between',
      padding:'20px 40px 30px 83px'
    },
    linkAction:{
      textTransform:'capitalize',
      fontWeight:'bolder',
      color: COLOR.LINK_ACTION
    },
    linkActionCancel:{
      textTransform:'capitalize',
      fontWeight:'normal',
      color: COLOR.LINK_ACTION
    }
}))

const PopupAlertCancelSubscription:React.FC<IPopupAlertCancelSubscription> = (props:IPopupAlertCancelSubscription) => {
  const { actionProp } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCancelSubscrition = () =>{
    actionProp()
    handleClose()
  }

  return (
    <div>
      <Link href="#" 
            onClick={handleClickOpen}
            className={classes.cancelSubcriptionLink} >
                Cancel Suscription
        </Link>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <div className={classes.logoWrapper}><img src={logo} alt="Logo" className={classes.logoApp} /></div>
        <DialogTitle id="alert-dialog-title">
          {"We are sad to see you go"}
        </DialogTitle>
        <DialogContent className={classes.contexWrapper}>
          <DialogContentText id="alert-dialog-description" className={classes.message}>
           We will save your account data and if you ever want to use<br/> 
           your account it will be here waiting for you when you get<br/> 
           back.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttonWrapper}>
          <Button className={classes.linkAction} onClick={handleClose}>Keep growing my sphere</Button>
          <Button className={classes.linkActionCancel} onClick={handleCancelSubscrition} autoFocus>
            End Subscription
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default PopupAlertCancelSubscription