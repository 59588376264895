import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    [theme.breakpoints.down('sm')]: {
      marginLeft:0
    },
    position: "relative",
    marginLeft:0
  },
  overlay: {
    width:120,height:120,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgb(0 0 0 / 0.5)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    color: "white",
  },
  imageInput: {
    display: "none"
  }
}))

export default useStyles