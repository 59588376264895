const RESTRICTED_ZIP_CODES = ["none"];
const PRINCIPAL_PROMOTION_CODES = [
  "Divein31",
  "60onKel",
  "60onBryan",
  "60onfree",
  "90onfree",
];
export const RESTRICTED_ZIP_CODES_MESSAGE =
  "Thank you for your interest in Accountabilities! Currently Hybrid Real Estate has exclusive use of this application within this area. Please reach out to Bryan Ranstad if you would like more information or to sign up. 541.228.6242 ranstadb@gmail.com";
export const PROMOTION_CODE_ERROR_MESSAGE =
  "Sorry but this promo code is not longer valid, try entering it again or use another code";

export const validateZipcode = (zipcode: string) => {
  if (zipcode === "") {
    return false;
  } else {
    const zp = zipcode;
    const result = RESTRICTED_ZIP_CODES.includes(zp) ? false : true;
    return result;
  }
};

export const validateRegistrationPromotionCodes = (code: string) => {
  const pCode = code.trim();
  return PRINCIPAL_PROMOTION_CODES.includes(pCode) ? false : true;
};
