import gql from "graphql-tag";
export const UploadContactsQuery = gql`
  mutation ($file: Upload!, $userId: Int!) {
    uploadContacts(file: $file, userId: $userId) {
      success
      successInfo {
        message
        duplicatesContactsEmail
      }
      error {
        recordId
        friendlyMessage
        errorCode
      }
    }
  }
`;

export const GetContacts = gql`
  query GetMycontacts {
    getMycontacts {
      id
      firstName
      lastName
      phone
      email
      address
      city
      state
      zip
      primary
      contactTypeId
    }
  }
`;
