import React from 'react';
import useStyles from './styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { BoxUserDetail } from '../box-user-detail/BoxUserDetail';

export interface HeaderElement {
  name: string
  icon: React.ReactNode
  action?: () => void
  hasMenu?: boolean,
  submenuItems?: Array<{
    action: () => void
    name: string
  }>
}

export interface MobileDrawerProps {
  headerElements: Array<HeaderElement>
}

const MobileDrawer: React.FC<MobileDrawerProps> = (props: MobileDrawerProps) => {
  const {headerElements} = props;
  const classes = useStyles();

  return (
    <div className={classes.drawer}>
      <List>
        {
          headerElements.map(element => (
            <>
              <ListItem button key={element.name}>
                <ListItemIcon onClick={element.action} >
                  {element.icon}
                </ListItemIcon>
                <ListItemText primary={element.name} />
              </ListItem>
              {
                element.submenuItems?.map(submenu => (
                  <ListItem button key={submenu.name} onClick={submenu.action}>
                    <ListItemText primary={submenu.name} />
                  </ListItem>
                ))
              }
               <ListItem button key={headerElements.length+1}>
               <BoxUserDetail/>
               </ListItem>
            </>
          ))
        }

      </List>
    </div>
  )
}

export default MobileDrawer;