import { Button } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import AvatarImage from '../../atoms/avatar-image/AvatarImage';
import useStyles from './styles';

export interface IAvatarImageWithUploadProps {
  profileUrl: string
  onUploadFile: (file: File) => void
  size?: number
}

const AvatarImageWithUpload: React.FC<IAvatarImageWithUploadProps> = (props: IAvatarImageWithUploadProps) => {
  const {profileUrl, onUploadFile, size} = props
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const classes = useStyles();
  const fileInputRef = useRef(null)

  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (validity && file) {
      onUploadFile(file);
    }
  }

  const onUploadClick = () => {
    const current = fileInputRef.current as any;
    if (current) {
      current.click();
    }
  }

  return (
    <div className={classes.container} onMouseEnter={() => {setShowOverlay(true)}} onMouseLeave={() => {setShowOverlay(false)}}>
      <input accept="image/*" className={classes.imageInput} id="upload-avatar-image-input" type="file" onChange={onChange} ref={fileInputRef}/>
      <AvatarImage profileUrl={profileUrl} size={size}/>
      {
        showOverlay ? (
          <div className={classes.overlay}>
            <Button variant="outlined" color="secondary" onClick={onUploadClick}>
              Edit photo
            </Button>
          </div>
        ) : null
      }
    </div>
  )
}

export default AvatarImageWithUpload;